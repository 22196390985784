import {
  FieldType,
  ShuttleSheetRequest,
  ShuttleSheetRequestPayload,
  ShuttleSheetType,
} from '../types/types';
import { normalizeFile } from '../utils/normalizeFile';
import { MS_SHUTTLESHEET_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function shuttleSheetTypeList(companyId: string): Promise<ShuttleSheetType[]> {
  return request
    .get(`${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/type/${companyId}`)
    .then((res) => res?.data);
}

function shuttleSheetTypeAllowedList(
  companyId: string
): Promise<ShuttleSheetType[]> {
  return request
    .get(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/type/${companyId}/allowed`
    )
    .then((res) => res?.data);
}

function createShuttleSheetType(
  companyId: string,
  payload: ShuttleSheetType
): Promise<ShuttleSheetType> {
  return request
    .post(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/type/${companyId}`,
      payload
    )
    .then((res) => res?.data);
}

function deleteShuttleSheetType(
  companyId: string,
  shuttleSheetTypeName: string
) {
  return request
    .delete(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/type/${companyId}/${shuttleSheetTypeName}`
    )
    .then((res) => res?.data);
}

function updateShuttleSheetType(
  companyId: string,
  shuttleSheetTypeId: string,
  payload: ShuttleSheetType
): Promise<ShuttleSheetType> {
  return request
    .patch(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/type/${companyId}/${shuttleSheetTypeId}`,
      payload
    )
    .then((res) => res?.data);
}

function userShuttleSheetsList(
  companyId: string,
  userId: string
): Promise<ShuttleSheetRequest[]> {
  return request
    .get(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/${companyId}/list/${userId}`
    )
    .then((res) => res?.data);
}

function shuttleSheetsToValidateList(
  companyId: string
): Promise<ShuttleSheetRequest[]> {
  return request
    .get(`${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/${companyId}`)
    .then((res) => res?.data);
}

function createShuttleSheet(
  companyId: string,
  shuttleSheetRequestPayload: ShuttleSheetRequestPayload
): Promise<ShuttleSheetRequest> {
  return request
    .post(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/${companyId}`,
      shuttleSheetRequestPayload
    )
    .then((res) => res?.data);
}

function updateShuttleSheet(
  companyId: string,
  shuttleSheetId: string,
  shuttleSheetRequestPayload: ShuttleSheetRequestPayload
): Promise<ShuttleSheetRequest> {
  return request
    .patch(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/${companyId}/${shuttleSheetId}`,
      shuttleSheetRequestPayload
    )
    .then((res) => res?.data);
}

function getShuttleSheet(
  companyId: string,
  shuttleSheetId: string
): Promise<ShuttleSheetRequest> {
  return request
    .get(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/${companyId}/${shuttleSheetId}`
    )
    .then((res) => res?.data);
}

function addShuttleSheetComment(
  companyId: string | undefined,
  shuttleSheetId: string | undefined,
  userId: string,
  comment: string
) {
  return request
    .post(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/${companyId}/${shuttleSheetId}/comment/${userId}`,
      {
        comment,
      }
    )
    .then((res) => res?.data);
}

function deleteShuttleSheet(companyId: string, shuttleSheetId: string) {
  return request
    .delete(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/${companyId}/${shuttleSheetId}`
    )
    .then((res) => res?.data);
}

function updateShuttleSheetFile(
  companyId: string,
  shuttleSheetId: string,
  file: File,
  type: string // ex: .jpg
) {
  return request
    .patch(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/${companyId}/${shuttleSheetId}/file`,
      { type, file: normalizeFile(file) },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

function shuttleSheetsFollowedByAccountantList(
  companyId: string
): Promise<ShuttleSheetRequest[]> {
  return request
    .get(
      `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/${companyId}/followed-by-accountant`
    )
    .then((res) => res?.data);
}

function getShuttleSheetFile(
  companyId: string | undefined,
  shuttleSheetId: string | undefined,
  shuttleSheetName: string | undefined,
  shuttleSheetType: FieldType
) {
  return `${MS_SHUTTLESHEET_ENDPOINT}/v2/shuttle-sheet/${companyId}/${shuttleSheetId}/file/${encodeURIComponent(
    shuttleSheetName ? shuttleSheetName : ''
  )}/${shuttleSheetType}`;
}

const ShuttleSheetService = {
  shuttleSheetTypeList,
  shuttleSheetTypeAllowedList,
  createShuttleSheetType,
  deleteShuttleSheetType,
  updateShuttleSheetType,
  userShuttleSheetsList,
  shuttleSheetsToValidateList,
  createShuttleSheet,
  getShuttleSheet,
  addShuttleSheetComment,
  deleteShuttleSheet,
  updateShuttleSheet,
  updateShuttleSheetFile,
  shuttleSheetsFollowedByAccountantList,
  getShuttleSheetFile,
};

export default ShuttleSheetService;
