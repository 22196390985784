import { Stack, Tabs } from '@mantine/core';
import {
  IconBuilding,
  IconChevronDown,
  IconClipboardList,
} from '@tabler/icons-react';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import CustomMenu from '../../../components/CustomMenu';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useDisplayTeamActivities from '../../../hooks/accessChecking/activities/useDisplayTeamActivities';
import type { CompanyResponse } from '../../../types/api/response/company';
import type { DivisionResponse } from '../../../types/api/response/division';
import { ModuleName } from '../../../types/api/response/module';
import {
  BuiltInPermissions,
  BuiltInRoles,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission, hasRole } from '../../../utils/authorization';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import Employees from '../../company/employees/Employees';
import HolidayCounters from '../../end-of-month/holiday-counter/components/HolidayCounters';
import ImportExportActivitiesButtons from '../../planning/components/ImportExportActivitiesButtons';
import PlanningScheduler, {
  PlanningSchedulerRef,
} from '../../planning/components/PlanningScheduler';
import LeavesAndAbsencesPendingRequests from '../../validations/leaves/LeavesAndAbsencesPendingRequests';
import WorkUnitsDashboard from './work-units-infos/WorkUnitsDashboard';
import WorkUnitsFixedPriceManagerTable from './WorkUnitsFixedPriceManagerTable';

type Props = {
  divisionConsultedId?: string;
  company: CompanyResponse;
  divisions: DivisionResponse[];
};

export default function WorkUnitDetails({
  divisionConsultedId,
  company,
  divisions,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const { getModule } = useModule();
  const [selectedDivision, setSelectedDivision] =
    useState<DivisionResponse | null>(null);
  const title = user.company?.divisionLabel
    ? user.company.divisionLabel
    : t('w.units');
  const dailyWorkModule = getModule(ModuleName.DailyWork);
  const canImportActivities = useDisplayTeamActivities();

  useEffect(() => {
    if (divisionConsultedId) {
      const findDivisionConsulted = divisions.find(
        (division) => division.id === divisionConsultedId
      );
      if (findDivisionConsulted) {
        setSelectedDivision(findDivisionConsulted);
      }
    } else {
      if (user.division) {
        const userWorkUnitIndex = divisions.findIndex(
          (div) => div.id === user.division?.id
        );
        if (userWorkUnitIndex !== -1) {
          setSelectedDivision(divisions[userWorkUnitIndex]);
        }
      } else {
        if (divisions.length) {
          setSelectedDivision(divisions[0]);
        }
      }
    }
  }, [user, divisions, divisionConsultedId]);

  function getLabel(divisionName: string) {
    return divisionName === NO_WORK_UNIT ? t('NO_WORK_UNIT') : divisionName;
  }

  const workUnits: { label: string; icon: ReactNode; onClick: () => void }[] =
    useMemo(() => {
      if (divisionConsultedId) {
        return divisions
          .filter(
            (division) =>
              divisionConsultedId && divisionConsultedId === division.id
          )
          .map((division) => ({
            label: getLabel(division.name),
            icon: <IconBuilding />,
            onClick: () => setSelectedDivision(division),
          }));
      } else {
        if (
          !hasRole([BuiltInRoles.ADMINISTRATOR], user) &&
          !hasPermission(
            {
              permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
              scope: ScopeEnum.ALL,
            },
            user
          )
        ) {
          return divisions
            .filter(
              (_div) =>
                _div.n1 === user.id ||
                _div.n2 === user.id ||
                _div.assistant === user.id ||
                _div.id === user.division?.id
            )
            .map((division) => ({
              label: getLabel(division.name),
              icon: <IconBuilding />,
              onClick: () => setSelectedDivision(division),
            }));
        } else {
          return divisions.map((division) => ({
            label: getLabel(division.name),
            icon: <IconBuilding />,
            onClick: () => setSelectedDivision(division),
          }));
        }
      }
    }, [
      hasRole([BuiltInRoles.ADMINISTRATOR], user),
      hasPermission(
        {
          permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
          scope: ScopeEnum.ALL,
        },
        user
      ),
      divisions,
      divisionConsultedId,
    ]);

  const actionButtons = useMemo(
    () => [
      <CustomMenu
        key={'divisions-menu'}
        buttonLabel={getLabel(
          selectedDivision?.name ? selectedDivision?.name : title
        )}
        buttonVariant={'filled'}
        leftSection={<IconClipboardList />}
        rightSection={<IconChevronDown />}
        menuLabel={title}
        menuItems={workUnits}
        width={250}
      />,
    ],
    [selectedDivision, workUnits]
  );
  const planningSchedulerRef = useRef<PlanningSchedulerRef>(null);
  const handleRefetch = () => {
    if (planningSchedulerRef.current) {
      planningSchedulerRef.current.refetchPlanning();
    }
  };

  const tabs = [
    {
      value: 'planning',
      label: 'Planning',
      component: selectedDivision ? (
        <Stack>
          {canImportActivities && !!planningSchedulerRef.current && (
            <ImportExportActivitiesButtons
              companyId={company.id}
              divisionIds={[selectedDivision.id]}
              month={
                planningSchedulerRef.current.visibleTimes?.start?.getMonth() + 1
              }
              year={planningSchedulerRef.current.visibleTimes?.start?.getFullYear()}
              refresh={handleRefetch}
            />
          )}
          <PlanningScheduler
            planningId="planning-id-to-print"
            selectedDivisions={[selectedDivision.id]}
            selectedTemplate={undefined}
            divisions={divisions}
            company={company}
            ref={planningSchedulerRef}
            useRefetch
          />
        </Stack>
      ) : null,
      isDisplayed: true,
    },
    {
      value: 'employees',
      label: t('w.employees'),
      component: (
        <Employees
          divisionId={selectedDivision?.id ? selectedDivision.id : ''}
          company={company}
        />
      ),
      isDisplayed: true,
    },
    {
      value: 'fixedPriceManager',
      label: t('w.fixedPriceManager'),
      component: (
        <WorkUnitsFixedPriceManagerTable
          companyId={company.id}
          selectedDivisions={[selectedDivision?.id ? selectedDivision.id : '']}
        />
      ),
      isDisplayed: dailyWorkModule?.active,
    },
    {
      value: 'leaves-and-absences',
      label: t('w.leavesAndAbsences'),
      component: (
        <LeavesAndAbsencesPendingRequests
          divisionId={selectedDivision?.id ? selectedDivision.id : ''}
          company={company}
        />
      ),
      isDisplayed: true,
    },
    {
      value: 'holiday-counters',
      label: t('w.holidayCounters'),
      component: (
        <HolidayCounters
          companyId={company.id}
          withUnitFilter={false}
          selectedDivisionIds={
            selectedDivision?.id ? [selectedDivision.id] : undefined
          }
        />
      ),
      isDisplayed: true,
    },
  ];

  return (
    <ScreenWrapper
      title={divisionConsultedId ? '' : title}
      actionButtons={actionButtons}
    >
      <Stack gap={'48px'}>
        <WorkUnitsDashboard
          selectedDivision={selectedDivision}
          company={company}
        />
        <Tabs defaultValue={'planning'}>
          <Tabs.List>
            {tabs
              .filter((tab) => tab.isDisplayed)
              .map((tab, index) => (
                <Tabs.Tab key={`tab-${tab.value}-${index}`} value={tab.value}>
                  {tab.label}
                </Tabs.Tab>
              ))}
          </Tabs.List>
          {tabs
            .filter((tab) => tab.isDisplayed)
            .map((tab, index) => (
              <Tabs.Panel
                p={'md'}
                key={`panel-${tab.value}-${index}`}
                value={tab.value}
              >
                {tab.component}
              </Tabs.Panel>
            ))}
        </Tabs>
      </Stack>
    </ScreenWrapper>
  );
}
