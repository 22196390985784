import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Box, Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCoins, IconEye, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useState } from 'react';

import ExpenseStatusBadge from '../../../../components/badges/ExpenseStatusBadge';
import CustomActionIconFile from '../../../../components/CustomActionIconFile';
import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import ExpenseReportService from '../../../../services/ExpenseReportService';
import { ExpenseStatusState } from '../../../../types/types';
import { handleOpenExpenseReportAttachmentFile } from '../../../../utils/attachmentFile';
import { getTotalHT, getTotalVAT } from '../../../../utils/expenseReport';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import ExpenseReportRequestForm from '../../../expense-reports/components/ExpenseReportRequestForm';
import { ExpenseToReimburse } from './ToReimburse';

const componentName = 'ExpenseReportsToReimburseRowDetails';

type Props = {
  expenseReports: ExpenseToReimburse[];
  refetch: () => void;
  companyId: string;
};

export default function ToReimburseRowDetails({
  companyId,
  expenseReports,
  refetch,
}: Props) {
  const { user, access_token } = useAuth();
  const { t, lang } = useI18n();

  const { mutate: reimburse } = useMutation({
    mutationFn: (expenseReportId: string) =>
      ExpenseReportService.reimburse(companyId, [expenseReportId]),
    onSuccess: () => {
      refetch();
    },
    onError: (error) =>
      showNotification({
        id: 'reimburse-expense-report-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: 'fullname', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status.state',
          label: t('w.status'),
          choices: [
            {
              value: ExpenseStatusState.PROCESSED,
              label: t(`expenseReport.status.state.PROCESSED`),
            },
            {
              value: ExpenseStatusState.VALID,
              label: t(`expenseReport.status.state.VALID`),
            },
            {
              value: ExpenseStatusState.WAITING,
              label: t(`expenseReport.status.state.WAITING`),
            },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  const columns: DataTableColumn<ExpenseToReimburse>[] = [
    {
      accessor: 'status.state',
      title: t('w.status'),
      sortable: true,
      ellipsis: true,
      render: ({ status }) => <ExpenseStatusBadge status={status} />,
    },
    {
      accessor: 'actions',
      title: t('w.actions'),
      textAlign: 'right',
      sortable: false,
      width: '0%',
      render: (expenseReport) => {
        return (
          <Group gap="xs" justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.see')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="green"
                onClick={() => handleOpenExpenseReportModalClick(expenseReport)}
              >
                <IconEye size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.markAsReimburse')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                onClick={() => reimburse(expenseReport.id)}
              >
                <IconCoins size={18} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        );
      },
    },
    {
      accessor: 'dateOfExpense',
      title: t('expenseReport.dateOfExpense'),
      sortable: true,
      ellipsis: true,
      render: ({ dateOfExpense }) => dayjs(dateOfExpense).format('DD/MM/YYYY'),
    },
    {
      accessor: 'type',
      title: t('w.type'),
      sortable: true,
      ellipsis: true,
      render: ({ type }) => t('expenseReport.type.' + type.toLowerCase()),
    },
    {
      accessor: 'label',
      title: t('w.wording'),
      sortable: true,
      ellipsis: true,
      render: ({ label }) => t('expenseReport.label.' + label),
    },
    {
      accessor: 'amountHT',
      title: t('w.amountHT'),
      sortable: true,
      ellipsis: true,
      render: ({ additionValueVATList, amount }) =>
        (getTotalHT(additionValueVATList, amount) || 0).toFixed(2),
    },
    {
      accessor: 'totalVAT',
      title: t('w.totalVAT'),
      sortable: true,
      ellipsis: true,
      render: ({ additionValueVATList }) =>
        (getTotalVAT(additionValueVATList) || 0).toFixed(2),
    },
    {
      accessor: 'amount',
      title: t('w.amountTTC'),
      sortable: true,
      ellipsis: true,
      render: ({ amount }) => amount,
    },
    {
      accessor: 'comment',
      title: t('w.comment'),
      sortable: true,
      ellipsis: true,
      width: 200,
      render: ({ comment }) => comment,
    },
    {
      accessor: 'attachment',
      title: t('expenseReport.receipt'),
      sortable: true,
      ellipsis: true,
      render: ({ companyId, id, attachment }) => {
        let extension = '';
        if (attachment) {
          const words = attachment.split('/');
          extension = words[words.length - 1]?.split('.')?.pop() || '';
        }
        return (
          <Group>
            {attachment && (
              <CustomTooltip label={t('w.seeAttachment')}>
                <CustomActionIconFile
                  fileName={attachment}
                  onClick={() =>
                    handleOpenExpenseReportAttachmentFile(
                      companyId,
                      id,
                      extension,
                      access_token,
                      t
                    )
                  }
                />
              </CustomTooltip>
            )}
          </Group>
        );
      },
    },
    {
      accessor: 'internal',
      title: t('w.internalGuest'),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: 'external',
      title: t('w.externalGuest'),
      sortable: true,
      ellipsis: true,
    },
  ];

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function handleOpenExpenseReportModalClick(
    expenseReport: ExpenseToReimburse
  ) {
    modals.open({
      modalId: `expense-report-${expenseReport.id}-to-see-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t('w.expenseReport')} - ${
          expenseReport.fullname
        }`}</Title>
      ),
      fullScreen: true,
      children: (
        <ExpenseReportRequestForm
          closeModal={() =>
            modals.close(
              `expense-report-${expenseReport.id}-to-see-or-update-modal`
            )
          }
          expenseReport={expenseReport}
          edit={false}
          userProfile={user} // user devrait être celui de la ndf, ici edit est false, ça ne pose pas de probleme car// on ne maj pas la donnée
        />
      ),
    });
  }

  return (
    <Box p="md">
      <Table
        pinFirstColumn
        pinLastColumn
        options={options}
        rows={expenseReports}
        columns={columns}
        lang={lang}
        withTableBorder={false}
        height={400}
      />
    </Box>
  );
}
