import FileSaver from 'file-saver';

export default function saveDownloadedfile(
  data: string | Blob,
  headers?: any
): void {
  let _fileName = 'export';
  if (headers?.['content-disposition']) {
    const contentDisposition = headers['content-disposition'];
    const _fileNameWithExtension =
      contentDisposition.match(/filename="(.+)"/)[1];
    if (_fileNameWithExtension) {
      _fileName = _fileNameWithExtension;
    }
  }

  FileSaver.saveAs(data, _fileName);
}
