import { Table } from '@ckprivate/ckf-ui';
import { Affix, Button, Group, rem, Text, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconInfoCircle } from '@tabler/icons-react';
import { DataTableColumn } from 'mantine-datatable';
import * as React from 'react';

import ButtonBack from '../../../../../components/ButtonBack';
import CopyButton from '../../../../../components/CopyButton';
import { useI18n } from '../../../../../contexts/I18nProvider';
import { TemplateKeyEnum } from '../../../../../types/api/response/documentGenerator';

type Row = { entityKeyTranslated: string; key: string };

export default function SupportedKeysLegend() {
  const { t } = useI18n();
  const rows: Row[] = Object.values(TemplateKeyEnum)
    .filter((k) => k !== TemplateKeyEnum.NOT_MAPPED_KEY)
    .map((templateKey) => ({
      entityKeyTranslated: t(`docgen.supportedKeys.${templateKey}`),
      key: `{{${templateKey}}}`,
    }));

  const columns: DataTableColumn<Row>[] = [
    {
      accessor: 'entityKeyTranslated',
      title: t('w.data'),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: 'key',
      title: t('w.key'),
      sortable: true,
      ellipsis: true,
      render: ({ key }) => (
        <Group>
          <CopyButton value={key} />
          <Text size="sm" fw="bold">
            {key}
          </Text>
        </Group>
      ),
    },
  ];

  function onSeeKeysClick() {
    const modalId = 'template-keys-modal';
    modals.open({
      modalId: modalId,
      title: (
        <Group>
          <ButtonBack onClose={() => modals.close(modalId)} />
          <Title size={'h3'} component="p">
            {t('w.availableKeysAndItsValues')}
          </Title>
        </Group>
      ),
      fullScreen: true,
      children: (
        <Table
          idAccessor={'key'}
          options={{
            sort: { columnAccessor: 'entityKeyTranslated', direction: 'asc' },
            search: {
              accessor: 'entityKeyTranslated',
              label: t('w.data'),
            },
          }}
          rows={rows}
          columns={columns}
          height={'calc(100vh - 100px)'}
        />
      ),
    });
  }

  return (
    <Affix position={{ bottom: rem(16), right: rem(16) }} zIndex={999}>
      <Button
        onClick={onSeeKeysClick}
        radius="xl"
        variant="light"
        leftSection={<IconInfoCircle />}
      >
        {t('w.seeAvailableKeys')}
      </Button>
    </Affix>
  );
}
