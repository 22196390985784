import type { FileWithPath } from '@mantine/dropzone';

import { ImportClientsResponse } from '../types/api/response/accountant';
import { normalizeFile } from '../utils/normalizeFile';
import { MS_HIFIVEWORK_PROFILE_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function exportClients(
  companyId: string,
  selectValid: boolean,
  selectInvalid: boolean
): Promise<any> {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/accountant/export/clients`,
      {
        params: { selectValid, selectInvalid },
        responseType: 'blob',
      }
    )
    .then((res) => res?.data);
}

function importClients(
  companyId: string,
  file: FileWithPath
): Promise<ImportClientsResponse> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/accountant/import/clients`,
      {
        file: normalizeFile(file),
        filename: file.name.split('.')[0],
        extension: file.name.split('.').pop(),
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

const CompanyAccountantService = {
  exportClients,
  importClients,
};

export default CompanyAccountantService;
