import {
  ActionIcon,
  Badge,
  Button,
  Fieldset,
  Flex,
  Group,
  Loader,
  Pill,
  Stack,
  Stepper,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconDownload,
  IconFileExport,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useState } from 'react';

import ButtonBack from '../../../../../components/ButtonBack';
import InformationMessage from '../../../../../components/InformationMessage';
import { useI18n } from '../../../../../contexts/I18nProvider';
import DocumentGeneratorService from '../../../../../services/DocumentGeneratorService';
import {
  DocGenActionType,
  DocGenExportType,
} from '../../../../../types/api/payload/documentGenerator';
import { Template } from '../../../../../types/api/response/documentGenerator';
import { UserResponse } from '../../../../../types/api/response/user';
import { getExtension } from '../../../../../utils/attachmentFile';
import saveDownloadedfile from '../../../../../utils/saveDownloadedFile';
import AddTemplateForm from './AddTemplateForm';
import SupportedKeysLegend from './SupportedKeysLegend';
import TemplateCard from './TemplateCard';

type Props = {
  companyId: string;
  users: UserResponse[];
};

export default function GenerateDocuments({ companyId, users }: Props) {
  const { t } = useI18n();
  const {
    data: templates,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['DocumentGeneratorService.getTemplates', companyId],
    queryFn: () => DocumentGeneratorService.getTemplates(companyId),
  });
  const [activeTemplate, setActiveTemplate] = useState<Template | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const nextStep = () =>
    setActiveStep((current) => (current < 2 ? current + 1 : current));
  const prevStep = () =>
    setActiveStep((current) => (current > 0 ? current - 1 : current));
  /*const [outputFormat, setOutputFormat] = useState<DocGenExportType>(
    DocGenExportType.DOCX
  );*/
  const outputFormat = DocGenExportType.DOCX;
  const activeTemplateExtension = getExtension(activeTemplate?.file || '');

  const { mutate: generateDocuments, isLoading: isLoadingDownloadContracts } =
    useMutation({
      mutationFn: (actionType: DocGenActionType) =>
        DocumentGeneratorService.generateDocuments(
          companyId,
          activeTemplate?.id!,
          users.map((user) => user.id),
          actionType,
          outputFormat
        ),
      onSuccess: (response, _actionType) => {
        if (_actionType === DocGenActionType.DOWNLOAD) {
          saveDownloadedfile(response.data, response.headers);
        } else {
          showNotification({
            id: 'DocumentGeneratorService.generateDocument-success',
            message: t('w.success'),
            color: 'green',
            icon: <IconCheck />,
          });
        }
      },
      onError: () => {
        showNotification({
          id: 'DocumentGeneratorService.downloadDocuments-error',
          message: t('w.error'),
          color: 'red',
          icon: <IconX />,
        });
      },
    });

  const { mutate: generateDocument } = useMutation({
    mutationFn: (payload: { userId: string; actionType: DocGenActionType }) =>
      DocumentGeneratorService.generateDocument(
        companyId,
        activeTemplate?.id!,
        payload.userId,
        payload.actionType,
        outputFormat
      ),
    onSuccess: (response, _payload) => {
      if (_payload.actionType === DocGenActionType.DOWNLOAD) {
        saveDownloadedfile(response.data, response.headers);
      } else {
        showNotification({
          id: 'DocumentGeneratorService.generateDocument-success',
          message: t('w.success'),
          color: 'green',
          icon: <IconCheck />,
        });
      }
    },
    onError: () => {
      showNotification({
        id: 'DocumentGeneratorService.generateDocument-error',
        message: t('w.error'),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  function onAddTemplateClick() {
    const modalId = 'generate-documents-add-template-modal';
    modals.open({
      modalId: modalId,
      title: (
        <Group>
          <ButtonBack onClose={() => modals.close(modalId)} />
          <Title size={'h3'} component="p">
            {t('w.generateDocuments')} - {t('w.addTemplate')}
          </Title>
        </Group>
      ),
      fullScreen: true,
      children: (
        <AddTemplateForm
          companyId={companyId}
          refetch={refetch}
          onClose={() => modals.close(modalId)}
        />
      ),
    });
  }

  return (
    <>
      <Stepper
        active={activeStep}
        onStepClick={setActiveStep}
        allowNextStepsSelect={!!activeTemplate}
      >
        <Stepper.Step
          label={t('w.firstStep')}
          description={t('w.templateSelection')}
        >
          <Stack>
            <InformationMessage
              message={t('docgen.clickTemplateToSelectOrDeselect')}
            />
            <Group mt="md">
              {isLoading ? (
                <Loader />
              ) : !!templates?.length ? (
                templates.map((template) => (
                  <TemplateCard
                    key={template.id}
                    companyId={companyId}
                    refetch={refetch}
                    template={template}
                    activeTemplate={activeTemplate}
                    setActiveTemplate={setActiveTemplate}
                  />
                ))
              ) : (
                <Text size="sm" c="dimmed">
                  {t('docgen.noTemplate')}
                </Text>
              )}

              <Button onClick={onAddTemplateClick}>{t('w.add')}</Button>
            </Group>
          </Stack>
        </Stepper.Step>
        <Stepper.Step label={t('w.lastStep')} description={t('w.summary')}>
          <Stack>
            <Fieldset legend={t('w.selectedTemplate')}>
              <Pill size="lg">{activeTemplate?.name}</Pill>
            </Fieldset>
            {/*<Fieldset legend={t('w.outputDocFormat')}>
              <Radio.Group
                value={outputFormat}
                onChange={(value) => setOutputFormat(value as DocGenExportType)}
                name="outputDocFormat"
              >
                <Group mt="xs">
                  <Radio value={DocGenExportType.DOCX} label={'.docx'} />
                  <Radio value={DocGenExportType.PDF} label={'.pdf'} />
                </Group>
              </Radio.Group>
            </Fieldset>*/}
          </Stack>
        </Stepper.Step>
      </Stepper>
      <Group justify="center" mt="md">
        <Button
          disabled={activeStep === 0}
          variant="default"
          onClick={prevStep}
        >
          {t('w.return')}
        </Button>
        {activeStep === 0 ? (
          <Button onClick={nextStep} disabled={!activeTemplate}>
            {t('w.nextStep')}
          </Button>
        ) : (
          <>
            <Button
              loading={isLoadingDownloadContracts}
              onClick={
                !!activeTemplate
                  ? () => generateDocuments(DocGenActionType.DOWNLOAD)
                  : undefined
              }
            >
              {t('docgen.generateAndDownloadZip', activeTemplateExtension)}
            </Button>
            <Button
              disabled
              loading={isLoadingDownloadContracts}
              onClick={
                !!activeTemplate
                  ? () => generateDocuments(DocGenActionType.DEPOSIT)
                  : undefined
              }
            >
              {t(
                'docgen.generateAndPersonalSpaceDeposit',
                activeTemplateExtension
              )}
            </Button>
          </>
        )}
      </Group>
      {activeStep !== 0 && (
        <Flex direction="column" mt="md" gap="sm">
          <InformationMessage message={t('docgen.orUnitActionsOnUser')} />
          <Fieldset legend={t('w.selectedUsers')}>
            <Group>
              {users.map((user) => (
                <Badge
                  key={user.id}
                  rightSection={
                    <>
                      <Tooltip
                        label={`${t('w.download')} ${activeTemplateExtension}`}
                      >
                        <ActionIcon
                          variant="subtle"
                          onClick={() =>
                            generateDocument({
                              userId: user.id,
                              actionType: DocGenActionType.DOWNLOAD,
                            })
                          }
                        >
                          <IconDownload
                            style={{ width: '70%', height: '70%' }}
                            stroke={1.5}
                          />
                        </ActionIcon>
                      </Tooltip>
                      <Tooltip
                        label={t(
                          'docgen.depositUserSpace',
                          activeTemplateExtension,
                          user.fullname
                        )}
                      >
                        <ActionIcon
                          variant="subtle"
                          onClick={() =>
                            generateDocument({
                              userId: user.id,
                              actionType: DocGenActionType.DEPOSIT,
                            })
                          }
                        >
                          <IconFileExport
                            style={{ width: '70%', height: '70%' }}
                            stroke={1.5}
                          />
                        </ActionIcon>
                      </Tooltip>
                    </>
                  }
                  variant="light"
                  size="lg"
                  styles={{
                    label: { textTransform: 'initial' },
                  }}
                >
                  {user.fullname}
                </Badge>
              ))}
            </Group>
          </Fieldset>
        </Flex>
      )}
      <SupportedKeysLegend />
    </>
  );
}
