import { Options, Table } from '@ckprivate/ckf-ui';
import { Badge } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import UnitBadge from '../../../../components/badges/UnitBadge';
import GoToUserButton from '../../../../components/GoToUserButton/GoToUserButton';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import CompanyService from '../../../../services/CompanyService';
import ExpenseReportService from '../../../../services/ExpenseReportService';
import type { DivisionResponse } from '../../../../types/api/response/division';
import { ExpenseReportObject } from '../../../../types/types';
import { getTotalHT, getTotalVAT } from '../../../../utils/expenseReport';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import ToReimburseRowDetails from './ToReimburseRowDetails';

const componentName = 'ExpenseReportsToReimburse';

type Row = ExpenseToReimburse & {
  userId: string;
  unit?: DivisionResponse;
  fullname?: string;
  totalHTComputed: number;
  totalVATComputed: number;
  totalTTCComputed: number;
  expenseReports: ExpenseToReimburse[];
};

export type ExpenseToReimburse = ExpenseReportObject & {
  fullname?: string;
  unit?: DivisionResponse;
};

export default function ToReimburse() {
  const { user } = useAuth();
  const { t, lang } = useI18n();
  const { id: companyId } = useCompany(user);

  const { divisions } = useFetchDivisions({ companyId });

  const { data: users, isLoading: isLoadingUsers } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, ['fullname', 'divisionId']),
  });

  const {
    data,
    isLoading: isLoadingExpenseReports,
    refetch,
  } = useQuery({
    queryKey: ['ExpenseReportService.getToReimburse', companyId],
    queryFn: () => ExpenseReportService.getToReimburse(companyId),
  });

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: 'fullname', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
  });

  const groupByUserId = (expenses: ExpenseToReimburse[]): Row[] => {
    const grouped: { [key: string]: Row } = expenses.reduce((acc, expense) => {
      if (!acc[expense.creator]) {
        acc[expense.creator] = {
          userId: expense.creator,
          fullname: expense.fullname,
          unit: expense.unit,
          totalHTComputed:
            getTotalHT(expense.additionValueVATList, expense.amount) || 0,
          totalVATComputed: getTotalVAT(expense.additionValueVATList) || 0,
          totalTTCComputed: expense.amount,
          expenseReports: [expense],
          ...expense,
        };
      }

      const existingExpense = acc[expense.creator].expenseReports.find(
        (t) => t.id === expense.id
      );
      if (!existingExpense) {
        acc[expense.creator].totalHTComputed +=
          getTotalHT(expense.additionValueVATList, expense.amount) || 0;

        acc[expense.creator].totalVATComputed +=
          getTotalVAT(expense.additionValueVATList) || 0;

        acc[expense.creator].totalTTCComputed += expense.amount || 0;

        acc[expense.creator].expenseReports.push(expense);
      }
      return acc;
    }, {} as { [key: string]: Row });

    return Object.values(grouped);
  };

  const rows: Row[] = useMemo(() => {
    if (!data?.length || !users?.length || !divisions?.length) return [];

    const expenses: ExpenseToReimburse[] = [...data];
    expenses.forEach((expenseReport) => {
      const whoUser = users.find((_user) => _user.id === expenseReport.creator);
      if (whoUser) {
        expenseReport.fullname = whoUser.fullname;
        expenseReport.unit = divisions.find(
          (item) => item.id === whoUser.divisionId
        );
      }
    });

    return groupByUserId(expenses);
  }, [data, users, divisions]);

  const columns: DataTableColumn<Row>[] = [
    {
      accessor: 'fullname',
      title: t('w.fullname'),
      sortable: true,
      ellipsis: true,
      render: ({ fullname, creator }) => (
        <GoToUserButton
          disabled
          userId={creator}
          userFullname={fullname as string}
        />
      ),
    },
    {
      accessor: 'unit',
      title: t('w.unit'),
      sortable: true,
      ellipsis: true,
      render: ({ unit }) => <UnitBadge unit={unit?.name} />,
    },
    {
      accessor: 'totalHTComputed',
      title: t('w.amountHT'),
      sortable: true,
      ellipsis: true,
      render: ({ totalHTComputed }) => (
        <Badge
          variant="outline"
          styles={{
            root: { textTransform: 'none' },
          }}
        >
          {totalHTComputed.toFixed(2)}
        </Badge>
      ),
    },
    {
      accessor: 'totalVATComputed',
      title: t('w.totalVAT'),
      sortable: true,
      ellipsis: true,
      render: ({ totalVATComputed }) => (
        <Badge
          variant="outline"
          styles={{
            root: { textTransform: 'none' },
          }}
        >
          {totalVATComputed.toFixed(2)}
        </Badge>
      ),
    },
    {
      accessor: 'totalTTCComputed',
      title: t('w.amountTTC'),
      sortable: true,
      ellipsis: true,
      render: ({ totalTTCComputed }) => (
        <Badge
          variant="outline"
          styles={{
            root: { textTransform: 'none' },
          }}
        >
          {totalTTCComputed.toFixed(2)}
        </Badge>
      ),
    },
  ];

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      idAccessor={'userId'}
      pinFirstColumn
      pinLastColumn
      options={options}
      rows={rows}
      columns={columns}
      lang={lang}
      fetching={isLoadingUsers || isLoadingExpenseReports}
      withTableBorder={false}
      height={'calc(100vh - 200px)'}
      rowExpansion={{
        content: ({ record }) => (
          <ToReimburseRowDetails
            expenseReports={record.expenseReports}
            refetch={refetch}
            companyId={companyId}
          />
        ),
      }}
    />
  );
}
