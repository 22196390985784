import { Table } from '@ckprivate/ckf-ui';
import { Group, Select, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import * as React from 'react';
import { useMemo } from 'react';

import CopyButton from '../../../../../components/CopyButton';
import { useI18n } from '../../../../../contexts/I18nProvider';
import DocumentGeneratorService from '../../../../../services/DocumentGeneratorService';
import {
  Template,
  TemplateKeyEnum,
  TemplateMapping,
} from '../../../../../types/api/response/documentGenerator';
import SupportedKeysLegend from './SupportedKeysLegend';

type Props = {
  companyId: string;
  template: Template;
};

export default function KeysMapping({ companyId, template }: Props) {
  const { t } = useI18n();
  const { data: mappings, refetch: refetchMappings } = useQuery({
    queryKey: [
      'DocumentGeneratorService.getTemplateMappings',
      companyId,
      template.id,
    ],
    queryFn: () =>
      DocumentGeneratorService.getTemplateMappings(companyId, template.id),
  });

  const { mutate: updateMapping, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: (payload: { mappingId: string; entityKey: TemplateKeyEnum }) =>
      DocumentGeneratorService.updateMapping(
        companyId,
        template.id,
        payload.mappingId,
        payload.entityKey === TemplateKeyEnum.NOT_MAPPED_KEY
          ? undefined
          : payload.entityKey
      ),
    onSuccess: () => {
      refetchMappings();
    },
    onError: () => {
      showNotification({
        id: 'DocumentGeneratorService.updateMapping-error',
        message: t('w.error'),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  const columns: DataTableColumn<TemplateMapping>[] = useMemo(
    () => [
      {
        accessor: 'entityKey',
        title: t('w.data'),
        sortable: true,
        ellipsis: true,
        render: ({ entityKey, id }) => (
          <Select
            searchable
            disabled={isLoadingUpdate}
            allowDeselect={false}
            value={!!entityKey ? entityKey : TemplateKeyEnum.NOT_MAPPED_KEY}
            data={Object.values(TemplateKeyEnum).map((key) => ({
              value: key,
              label: t(`docgen.supportedKeys.${key}`),
            }))}
            error={!entityKey}
            onChange={(value) =>
              updateMapping({
                mappingId: id,
                entityKey: value as TemplateKeyEnum,
              })
            }
          />
        ),
      },
      {
        accessor: 'key',
        title: t('w.key'),
        sortable: true,
        ellipsis: true,
        render: ({ templateKey, entityClass, entityField }) => (
          <Group>
            <CopyButton value={`{{${templateKey}}}`} />
            <Text
              size="sm"
              fw="bold"
              c={!entityClass || !entityField ? 'red' : undefined}
            >{`{{${templateKey}}}`}</Text>
          </Group>
        ),
      },
    ],
    [mappings]
  );

  return (
    <>
      <Table
        idAccessor="key"
        rows={mappings || []}
        columns={columns}
        height={'calc(100vh - 100px)'}
      />
      <SupportedKeysLegend />
    </>
  );
}
