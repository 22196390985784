import { Button } from '@mantine/core';
import { IconChevronLeft } from '@tabler/icons-react';
import React from 'react';

import { useI18n } from '../contexts/I18nProvider';

type Props = {
  onClose: () => void;
  isLoading?: boolean;
};

export default function ButtonBack({ onClose, isLoading = false }: Props) {
  const { t } = useI18n();

  return (
    <Button
      leftSection={<IconChevronLeft />}
      loading={isLoading}
      onClick={onClose}
      variant={'outline'}
    >
      {t('w.return')}
    </Button>
  );
}
