import { ActionIcon, Flex } from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import type {
  DatePickerType,
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import dayjs from 'dayjs';

import { useI18n } from '../contexts/I18nProvider';
import LabelText from './LabelText/LabelText';

type Props = {
  value: DateValue | DatesRangeValue | Date[];
  onChange: (value: DateValue | DatesRangeValue | Date[]) => void;
  type?: DatePickerType;
};

export default function CustomMonthPickerInput({
  value,
  onChange,
  type = 'default',
}: Props) {
  const { t } = useI18n();

  const handlePrevMonth = () => {
    if (value) {
      const newDate = dayjs(value as Date)
        .subtract(1, 'month')
        .toDate();
      onChange(newDate);
    }
  };

  const handleNextMonth = () => {
    if (value) {
      const newDate = dayjs(value as Date)
        .add(1, 'month')
        .toDate();
      onChange(newDate);
    }
  };

  return (
    <Flex align="flex-end" gap="xs">
      <ActionIcon
        onClick={handlePrevMonth}
        variant="default"
        radius="xl"
        aria-label="prev"
        mb="xs"
      >
        <IconChevronLeft style={{ width: '70%', height: '70%' }} stroke={1.5} />
      </ActionIcon>
      <MonthPickerInput
        label={<LabelText text={t('w.monthConcerned')} />}
        value={value}
        onChange={onChange}
        type={type}
      />
      <ActionIcon
        onClick={handleNextMonth}
        variant="default"
        radius="xl"
        aria-label="next"
        mb="xs"
      >
        <IconChevronRight
          style={{ width: '70%', height: '70%' }}
          stroke={1.5}
        />
      </ActionIcon>
    </Flex>
  );
}
