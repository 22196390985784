import { Fieldset, Flex, Text } from '@mantine/core';
import React, { useState } from 'react';

import InformationMessage from '../../../../../components/InformationMessage';
import { useI18n } from '../../../../../contexts/I18nProvider';
import type {
  ModuleDetails,
  SynchroOpenpayeParams,
} from '../../../../../types/api/response/module';
import SynchroOpenpayefeatureConnectionForm from './SynchroOpenpayefeatureConnectionForm';
import SynchroOpenpayeFeatureDossierForm from './SynchroOpenpayeFeatureDossierForm';

type Props = {
  module: ModuleDetails | null;
  companyId: string;
  isAccountant?: boolean;
};

export default function FeatureSynchroOpenpayeParams({
  module,
  companyId,
  isAccountant = false,
}: Props) {
  const { t } = useI18n();
  const [moduleParams, setModuleParams] = useState(
    module?.configuration?.params as SynchroOpenpayeParams
  );

  return (
    <>
      <Fieldset legend={t('w.connectionOpenpaye')} mb="md">
        <InformationMessage
          message={
            <>
              <Flex
                justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
                gap="xs"
                mb="md"
              >
                <Text size={'sm'}>
                  {t('com.feature.openPaye.knowOpenpayeIdentifiants') + ' :'}
                </Text>
                <Text
                  component={'a'}
                  target="_blank"
                  href={'https://app.openpaye.co/MyApp/0/APIAccess/Edit'}
                  size={'xs'}
                  td="underline"
                >
                  https://app.openpaye.co/MyApp/0/APIAccess/Edit
                </Text>
              </Flex>
            </>
          }
        />

        <SynchroOpenpayefeatureConnectionForm
          module={module}
          moduleParams={moduleParams}
          setModuleParams={setModuleParams}
          companyId={companyId}
        />
      </Fieldset>
      <SynchroOpenpayeFeatureDossierForm
        module={module}
        moduleParams={moduleParams}
        setModuleParams={setModuleParams}
        companyId={companyId}
        isAccountant={isAccountant}
      />
    </>
  );
}
