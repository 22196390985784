import type { FileWithPath } from '@mantine/dropzone';

export type DocgenUpdateRequest = {
  companyId: string;
  templateId: string;
  templateName: string;
  file?: FileWithPath;
};

export enum DocGenExportType {
  DOCX = 'DOCX',
  PDF = 'PDF',
}

export enum DocGenActionType {
  DEPOSIT = 'DEPOSIT',
  DOWNLOAD = 'DOWNLOAD',
}
