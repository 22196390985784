import type { FileWithPath } from '@mantine/dropzone';

import type { RepetitionType } from '../types/api/payload/leave';
import type { LeaveResponse } from '../types/api/response/leave';
import { normalizeFile } from '../utils/normalizeFile';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getLeavesPendingValidation(
  companyId: string
): Promise<LeaveResponse[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/leave/pending-validation/${companyId}`)
    .then((res) => res?.data);
}

function getLeavesHistory({
  companyId,
  divisionId,
  userId,
}: {
  companyId?: string;
  divisionId?: string;
  userId?: string;
}): Promise<LeaveResponse[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/leave/history`, {
      params: {
        companyId: companyId,
        divisionId: divisionId,
        userId: userId,
      },
    })
    .then((res) => res?.data);
}

function create(
  companyId: string,
  who: string,
  type: string,
  periodStart: string,
  periodEnd: string,
  comment: string,
  startHalf: boolean,
  endHalf: boolean,
  repetitionType: RepetitionType,
  repetitionEndDate: string
): Promise<LeaveResponse[]> {
  return request
    .post(`${MS_PLANNING_ENDPOINT}/v2/leave/${companyId}/create`, {
      who,
      type,
      periodStart,
      periodEnd,
      comment,
      startHalf,
      endHalf,
      repetitionType,
      repetitionEndDate,
    })
    .then((res) => res?.data);
}

function cancel(companyId: string, leaveId: string): Promise<LeaveResponse> {
  return request
    .delete(`${MS_PLANNING_ENDPOINT}/v2/leave/${companyId}/${leaveId}`)
    .then((res) => res?.data);
}

function declineRequest(
  companyId: string,
  leaveId: string,
  comment: string
): Promise<LeaveResponse> {
  return request
    .patch(`${MS_PLANNING_ENDPOINT}/v2/leave/${companyId}/${leaveId}/decline`, {
      comment,
    })
    .then((res) => res?.data);
}

function validateRequest(
  companyId: string,
  leaveId: string
): Promise<LeaveResponse> {
  return request
    .patch(`${MS_PLANNING_ENDPOINT}/v2/leave/${companyId}/${leaveId}/accept`)
    .then((res) => res?.data);
}

function uploadFile(
  companyId: string,
  leaveId: string,
  file: FileWithPath
): Promise<LeaveResponse> {
  const fileExt = file.name.split('.').pop();
  return request.patch(
    `${MS_PLANNING_ENDPOINT}/v2/leave/${companyId}/${leaveId}/upload-file`,
    { file: normalizeFile(file), filename: file.name, extension: fileExt },
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
}

function attachmentUrl(leaveId: string): string {
  return `${MS_PLANNING_ENDPOINT}/v2/leave/leaveAttachment/${leaveId}`;
}

const LeaveService = {
  getLeavesPendingValidation,
  getLeavesHistory,
  create,
  cancel,
  declineRequest,
  validateRequest,
  uploadFile,
  attachmentUrl,
};

export default LeaveService;
