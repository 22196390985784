import { Button, Fieldset, Flex, SimpleGrid, Text } from '@mantine/core';
import * as React from 'react';

import InformationMessage from '../../../../../components/InformationMessage';
import { useI18n } from '../../../../../contexts/I18nProvider';

export default function SilaeConnectDossierForm() {
  const { t } = useI18n();
  return (
    <SimpleGrid cols={2}>
      <Fieldset variant="filled" legend={t('silaeConnect.dossiersOnSilae')}>
        <Text c="dimmed" size="sm">
          {t('w.noResults')}
        </Text>
      </Fieldset>
      <Fieldset
        variant="filled"
        legend={t('silaeConnect.selectedDossiersForSynchro')}
      >
        <InformationMessage message={t('silaeConnect.synchronizeMessage')} />
        <Flex
          justify={'flex-end'}
          gap={'md'}
          direction="row"
          wrap="wrap"
          mt={'md'}
        >
          <Button disabled variant={'outline'}>
            {t('w.synchronize')}
          </Button>
        </Flex>
      </Fieldset>
    </SimpleGrid>
  );
}
