import type { FileWithPath } from '@mantine/dropzone';

import type {
  CompanyGroup,
  SubscribeRequest,
  UpdateCompanyRequest,
} from '../types/api/payload/company';
import type { UserShowFieldEnum } from '../types/api/payload/user';
import type {
  CompanyResponse,
  ImportDivisionResultResponse,
  ImportEmployeeResultResponse,
} from '../types/api/response/company';
import type { UserResponse } from '../types/api/response/user';
import { normalizeFile } from '../utils/normalizeFile';
import { MS_HIFIVEWORK_PROFILE_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function subscribeViaLoginPage(
  access_token: string,
  data: SubscribeRequest
): Promise<void> {
  return request
    .post(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/subscribe`, data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res?.data);
}

function subscribeCompanyViaPaymentAccountCompany(
  paymentAccountCompanyId: string,
  data: SubscribeRequest,
  linkAsAccountant: boolean
): Promise<void> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/subscribe/${paymentAccountCompanyId}?linkAsAccountant=${linkAsAccountant}`,
      data
    )
    .then((res) => res?.data);
}

function getCompany(companyId: string): Promise<CompanyResponse> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}`)
    .then((res) => res?.data);
}

function updateCompany(
  companyId: string,
  data: UpdateCompanyRequest
): Promise<CompanyResponse> {
  return request
    .patch(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}`, data)
    .then((res) => res?.data);
}

function updateLogo(
  companyId: string,
  file: File,
  extension: string
): Promise<void> {
  return request.patch(
    `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/logo`,
    { file: normalizeFile(file), extension: extension },
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
}

function getLogoPath(companyId: string): string {
  return `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/logo/200/200`;
}

function importData(
  type: 'divisions' | 'employees',
  file: FileWithPath,
  companyId: string
): Promise<ImportDivisionResultResponse | ImportEmployeeResultResponse> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/import/${type}`,
      {
        file: normalizeFile(file),
        filename: file.name.split('.')[0],
        extension: file.name.split('.').pop(),
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => {
      if (type === 'divisions') {
        return res?.data as ImportDivisionResultResponse;
      } else {
        return res?.data as ImportEmployeeResultResponse;
      }
    });
}

function getCompanyHomeData(
  companyId: string
): Promise<[number, number, number]> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/home-data`)
    .then((res) => res?.data);
}

function inviteUser(companyId: string, userId: string): Promise<void> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/users/invite?userId=${userId}`
    )
    .then((res) => res?.data);
}

function createUser(
  companyId: string,
  onBoarding: boolean,
  data: any,
  access_token: string
): Promise<UserResponse> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/users?onBoarding=${onBoarding}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function listEmployees(
  companyId: string,
  show: (keyof typeof UserShowFieldEnum)[]
): Promise<UserResponse[]> {
  if (show.length > 0) {
    return request
      .get(
        `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/users?${show
          .map((item) => `show=${item}`)
          .join('&')}`
      )
      .then((res) => res?.data);
  } else {
    return request
      .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/users`)
      .then((res) => res?.data);
  }
}

function exportEmployees(
  companyId: string,
  selectValid: boolean,
  selectInvalid: boolean,
  type: string,
  access_token: string,
  divisionsId: string[]
) {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/${companyId}/users/export/${type}`,
      {
        params: { selectValid, selectInvalid, divisionsId },
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((res) => res?.data);
}

function listCompanyGroup(companyId: string): Promise<CompanyGroup[]> {
  return request
    .get(`${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/group/${companyId}`)
    .then((res) => res?.data);
}

function createOrUpdateCompanyGroup(
  companyId: string,
  payload: CompanyGroup
): Promise<CompanyGroup> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/group/${companyId}`,
      payload
    )
    .then((res) => res?.data);
}

function deleteCompanyGroup(
  companyId: string,
  companyGroupId: string
): Promise<void> {
  return request
    .delete(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/company/group/${companyId}/${companyGroupId}`
    )
    .then((res) => res?.data);
}

const CompanyService = {
  subscribeViaLoginPage,
  subscribeCompanyViaPaymentAccountCompany,
  getCompany,
  updateCompany,
  updateLogo,
  getLogoPath,
  importData,
  getCompanyHomeData,
  inviteUser,
  createUser,
  listEmployees,
  exportEmployees,
  listCompanyGroup,
  createOrUpdateCompanyGroup,
  deleteCompanyGroup,
};

export default CompanyService;
