import { Button } from '@mantine/core';
import React from 'react';

import { useI18n } from '../contexts/I18nProvider';

type Props = {
  onClose: () => void;
  variant?: 'subtle' | 'outline' | 'filled';
  isLoading?: boolean;
};

export default function ButtonCancel({
  onClose,
  isLoading = false,
  variant = 'subtle',
}: Props) {
  const { t } = useI18n();

  return (
    <Button loading={isLoading} onClick={onClose} variant={variant}>
      {t('w.cancel')}
    </Button>
  );
}
