import {
  Button,
  Fieldset,
  Flex,
  PasswordInput,
  SimpleGrid,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import ButtonSave from '../../../../../components/ButtonSave';
import InformationMessage from '../../../../../components/InformationMessage';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../../contexts/I18nProvider';
import ModuleWithParamsService from '../../../../../services/ModuleWithParamsService';
import {
  ModuleDetails,
  SilaeConnectParams as SilaeConnectParamsType,
  SilaeConnectParamsModuleResponse,
} from '../../../../../types/api/response/module';
import SilaeConnectDossierForm from './SilaeConnectDossierForm';

type Props = {
  module: ModuleDetails | null;
  companyId: string;
};

type FormValues = SilaeConnectParamsType & { isTest: boolean };

export default function SilaeConnectParams({ module, companyId }: Props) {
  const { t } = useI18n();
  const [moduleParams, setModuleParams] = useState(
    module?.configuration?.params as SilaeConnectParamsType
  );
  const queryClient = useQueryClient();

  const { mutate: updateModuleParamsSilaeConnect, isLoading } = useMutation({
    mutationFn: (variables: { apiUrl: string; payload: any }) =>
      ModuleWithParamsService.updateModuleParams(
        variables.apiUrl,
        companyId,
        variables.payload
      ),
    onSuccess: (_data: SilaeConnectParamsModuleResponse, _variables) => {
      showNotification({
        id: `success-update-module-params`,
        message: Boolean(_variables?.payload?.isTest)
          ? t('silaeConnect.dontForgotToSaveCredentials')
          : t('w.success'),
        color: Boolean(_variables?.payload?.isTest) ? 'orange' : 'green',
        icon: <IconCheck />,
      });
      queryClient.invalidateQueries({
        queryKey: ['CkModuleManagerServiceMS.list', companyId],
      });
      setModuleParams(_data.params);
    },
    onError: (error: any) => {
      if (
        error?.response?.data?.error_key === 'silaeConnect.credentialsNotValid'
      ) {
        showNotification({
          id: `error-update-module-params`,
          title: t('w.error'),
          message: t('silaeConnect.credentialsNotValid'),
          color: 'red',
          icon: <IconX />,
        });
      } else {
        showNotification({
          id: `error-update-module-params`,
          title: t('w.error'),
          message: t('error.updateModule'),
          color: 'red',
          icon: <IconX />,
        });
      }
    },
  });

  const form = useForm<FormValues>({
    initialValues: {
      clientId: moduleParams?.clientId || '',
      clientSecret: moduleParams?.clientSecret || '',
      subscriptionKey: moduleParams?.subscriptionKey || '',
      isTest: true,
    },
  });

  function submit(values: FormValues) {
    if (module) {
      updateModuleParamsSilaeConnect({
        apiUrl: module.description.url,
        payload: {
          active: true,
          ...values,
        },
      });
    }
  }

  return (
    <>
      <Fieldset legend={t('w.connectionSilae')} mb="md">
        <InformationMessage
          message={
            <>
              <Flex
                justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
                gap="xs"
                mb="md"
              >
                <Text size={'sm'}>
                  {t('com.feature.silaeConnect.knowIdentifiants') + ' :'}
                </Text>
                <Text
                  component={'a'}
                  target="_blank"
                  href={
                    'https://silae-api.document360.io/docs/partenaire-premiere-connexion-au-portail-api'
                  }
                  size={'xs'}
                  td="underline"
                >
                  https://silae-api.document360.io/docs/partenaire-premiere-connexion-au-portail-api
                </Text>
              </Flex>
            </>
          }
        />
        <form
          onSubmit={form.onSubmit((values) => {
            submit({ ...values, isTest: false });
          })}
        >
          <SimpleGrid cols={2}>
            <PasswordInput
              required
              label={<LabelText text={t('w.clientId')} />}
              placeholder="XXX"
              value={form.values.clientId}
              onChange={(event) =>
                form.setFieldValue('clientId', event.currentTarget.value)
              }
            />

            <PasswordInput
              required
              label={<LabelText text={t('w.clientSecret')} />}
              placeholder="XXX"
              value={form.values.clientSecret}
              onChange={(event) =>
                form.setFieldValue('clientSecret', event.currentTarget.value)
              }
            />

            <PasswordInput
              required
              label={<LabelText text={t('w.subscriptionKey')} />}
              placeholder="XXX"
              value={form.values.subscriptionKey}
              onChange={(event) =>
                form.setFieldValue('subscriptionKey', event.currentTarget.value)
              }
            />
          </SimpleGrid>
          <Flex
            justify={'flex-end'}
            gap={'md'}
            direction="row"
            wrap="wrap"
            mt={'md'}
          >
            <Button
              variant={'outline'}
              onClick={() => submit({ ...form.values, isTest: true })}
              loading={isLoading}
            >
              {t('w.test')}
            </Button>
            <ButtonSave isLoading={isLoading} />
          </Flex>
        </form>
      </Fieldset>
      <SilaeConnectDossierForm />
    </>
  );
}
