import { Button, Stack, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCirclePlus, IconClockEdit, IconX } from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useAppBar } from '../../contexts/AppBarProvider';
import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import { useModule } from '../../contexts/ModuleProvider';
import ScreenWrapper from '../../core/layouts/components/ScreenWrapper';
import useCompany from '../../hooks/useCompany';
import useFetchDivisions from '../../hooks/useFetchDivisions';
import LeaveTypeService from '../../services/LeaveTypeService';
import { DayOfWeek } from '../../types/api/common';
import type {
  DailyWorkDailyTemplateResponse,
  DailyWorkWeeklyTemplateResponse,
  WeeklyPeriod,
} from '../../types/api/response/dailyWork';
import { ModuleName } from '../../types/api/response/module';
import { BuiltInPermissions, ScopeEnum } from '../../types/api/response/role';
import { hasPermission, isAccountant } from '../../utils/authorization';
import handleErrorMessage from '../../utils/handleErrorMessage';
import BlockingPeriodsStepper from '../company/periods/components/blocking-periods/BlockingPeriodsStepper';
import CompanyMandatoryLeavesStepper from '../company/periods/components/company-mandatory-leaves/CompanyMandatoryLeavesStepper';
import { ActivityTemplatesCrud } from './components/ActivityTemplatesCrud';
import PlanningScheduler, {
  PlanningSchedulerRef,
} from './components/PlanningScheduler';

export const weeklyPeriods: WeeklyPeriod[] = [
  { day: DayOfWeek.MONDAY, period: [] },
  { day: DayOfWeek.TUESDAY, period: [] },
  { day: DayOfWeek.WEDNESDAY, period: [] },
  { day: DayOfWeek.THURSDAY, period: [] },
  { day: DayOfWeek.FRIDAY, period: [] },
  { day: DayOfWeek.SATURDAY, period: [] },
  { day: DayOfWeek.SUNDAY, period: [] },
];

export default function PlanningView() {
  const planningId = 'planning-id-to-print';
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId, company } = useCompany(user);
  const { getModule } = useModule();
  const {
    appBarSelectedDivisions,
    setIsAppBarUsed,
    setDivisionsToSelect,
    setAppBarSelectedDivisions,
  } = useAppBar();
  const { divisions } = useFetchDivisions({ companyId });
  const queryClient = useQueryClient();
  const [openedTemplates, { open, close }] = useDisclosure(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    DailyWorkDailyTemplateResponse | DailyWorkWeeklyTemplateResponse | undefined
  >(undefined);
  const { data: leaveTypes } = useQuery({
    queryKey: ['LeaveTypeService.getLeaveTypes', companyId],
    queryFn: () => LeaveTypeService.getLeaveTypes(companyId),
    onError: (err) =>
      showNotification({
        id: 'get-all-leaves-types-list-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });
  const handleRefetch = () => {
    if (planningSchedulerRef.current) {
      planningSchedulerRef.current.refetchPlanning();
    }
  };
  const selectedDivisions: string[] = useMemo(() => {
    if (!divisions || !appBarSelectedDivisions?.length) return [];

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });
    return result;
  }, [divisions, appBarSelectedDivisions]);

  const dailyWorkModule = getModule(ModuleName.DailyWork);

  useEffect(() => {
    setIsAppBarUsed(true);
    return function cleanup() {
      setIsAppBarUsed(false);
    };
  }, []);

  useEffect(() => {
    buildAppbarDivisions();
  }, [divisions]);

  function buildAppbarDivisions() {
    const _divisions = filterDivisionsByAccessRight();
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions(divisionsNames);
  }

  function filterDivisionsByAccessRight() {
    let _divisions = cloneDeep(divisions);

    // managers only see divisions where they are n1, n2 or assistant
    if (
      !hasPermission(
        [
          {
            permission: BuiltInPermissions.AuthorizedReadPlanning,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
            scope: ScopeEnum.ALL,
          },
        ],
        user
      )
    ) {
      _divisions = _divisions.filter(
        (_div) =>
          _div.n1 === user.id ||
          _div.n2 === user.id ||
          _div.assistant === user.id ||
          _div.id === user.division?.id
      );
    }
    return _divisions;
  }

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['CkModuleManagerServiceMS.list', companyId],
    });
  }, []);

  const planningSchedulerRef = useRef<PlanningSchedulerRef>(null);

  const actionButtons = useMemo(() => {
    const result = [];

    if (!!divisions.length) {
      if (
        isAccountant(user, companyId) ||
        hasPermission(
          {
            permission: BuiltInPermissions.ConfigureBlockingPeriods,
            scope: ScopeEnum.ALL,
          },
          user
        )
      ) {
        result.push(
          <Button
            variant="subtle"
            key={'addBlockingPeriod-button'}
            leftSection={<IconCirclePlus />}
            onClick={() => {
              modals.open({
                id: 'addBlockingPeriod-modal',
                title: (
                  <Title size={'h3'} component="p">
                    {t('w.addBlockingPeriod')}
                  </Title>
                ),
                size: '80%',
                children: (
                  <BlockingPeriodsStepper
                    divisions={filterDivisionsByAccessRight()}
                    onClose={() => modals.close('addBlockingPeriod-modal')}
                    refresh={handleRefetch}
                  />
                ),
              });
            }}
          >
            {t('w.addBlockingPeriod')}
          </Button>
        );
      }

      if (
        !!leaveTypes?.length &&
        (isAccountant(user, companyId) ||
          hasPermission(
            {
              permission:
                BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
              scope: ScopeEnum.ALL,
            },
            user
          ))
      ) {
        result.push(
          <Button
            variant="subtle"
            key={'addCompanyLeave-button'}
            leftSection={<IconCirclePlus />}
            onClick={() => {
              modals.open({
                id: 'addCompanyLeave-modal',
                title: (
                  <Title size={'h3'} component="p">
                    {t('w.addCompanyLeave')}
                  </Title>
                ),
                size: '80%',
                children: (
                  <CompanyMandatoryLeavesStepper
                    divisions={filterDivisionsByAccessRight()}
                    allCompanyLeaveTypesListData={leaveTypes}
                    refresh={handleRefetch}
                    onClose={() => modals.close('addCompanyLeave-modal')}
                  />
                ),
              });
            }}
          >
            {t('w.addCompanyLeave')}
          </Button>
        );
      }
    }

    if (dailyWorkModule?.active && !openedTemplates) {
      result.push(
        <Button
          variant="subtle"
          key={'daily-work-button'}
          disabled={!selectedDivisions.length}
          onClick={() => {
            open();
            setSelectedTemplate(undefined);
          }}
          leftSection={<IconClockEdit />}
        >
          {t('w.presenceEntry')}
        </Button>
      );
    }

    return result;
  }, [
    selectedDivisions,
    dailyWorkModule,
    company,
    openedTemplates,
    divisions,
    leaveTypes,
  ]);

  return (
    <ScreenWrapper title={'Planning'} actionButtons={actionButtons} date>
      <Stack mb={'md'} gap="md">
        {selectedDivisions.length > 0 &&
          dailyWorkModule?.active &&
          !!planningSchedulerRef.current && (
            <ActivityTemplatesCrud
              onClose={close}
              open={openedTemplates}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              exportDate={planningSchedulerRef.current?.visibleTimes?.start!}
              refresh={handleRefetch}
              companyId={companyId}
              selectedDivisions={selectedDivisions}
            />
          )}
        {company && (
          <PlanningScheduler
            planningId={planningId}
            selectedDivisions={selectedDivisions}
            selectedTemplate={selectedTemplate}
            divisions={divisions}
            company={company}
            ref={planningSchedulerRef}
            useRefetch
          />
        )}
      </Stack>
    </ScreenWrapper>
  );
}
