import {
  ActionIcon,
  CopyButton as CopyButtonMantine,
  Tooltip,
} from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import * as React from 'react';

import { useI18n } from '../contexts/I18nProvider';

type Props = {
  value: string;
};

export default function CopyButton({ value }: Props) {
  const { t } = useI18n();
  return (
    <CopyButtonMantine value={value}>
      {({ copied, copy }) => (
        <Tooltip label={copied ? t('w.copied') : t('w.copy')} withArrow>
          <ActionIcon
            color={copied ? 'green' : 'gray'}
            variant="subtle"
            onClick={copy}
          >
            {copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
          </ActionIcon>
        </Tooltip>
      )}
    </CopyButtonMantine>
  );
}
