import { Group, Stack, TextInput } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { isNotEmpty, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';

import ButtonCancel from '../../../../../components/ButtonCancel';
import ButtonSave from '../../../../../components/ButtonSave';
import CustomDropzone from '../../../../../components/CustomDropzone/CustomDropzone';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../../contexts/I18nProvider';
import DocumentGeneratorService from '../../../../../services/DocumentGeneratorService';
import SupportedKeysLegend from './SupportedKeysLegend';

type Props = {
  companyId: string;
  refetch: () => void;
  onClose: () => void;
};

type FormValues = {
  templateName: string;
  file: FileWithPath[];
};

export default function AddTemplateForm({
  companyId,
  refetch,
  onClose,
}: Props) {
  const { t } = useI18n();
  const form = useForm<FormValues>({
    initialValues: { file: [], templateName: '' },
    validate: {
      file: (value) => (value.length === 0 ? t('w.required') : null),
      templateName: isNotEmpty(t('w.required')),
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload: FormValues) =>
      DocumentGeneratorService.createTemplate(
        companyId,
        payload.templateName,
        payload.file[0]
      ),
    onSuccess: () => {
      refetch();
      onClose();
    },
    onError: () => {
      showNotification({
        id: 'DocumentGeneratorService.createTemplate-error',
        message: t('w.error'),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  return (
    <>
      <form onSubmit={form.onSubmit((values) => mutate(values))}>
        <Stack>
          <TextInput
            required
            label={<LabelText text={t('w.name')} />}
            {...form.getInputProps('templateName')}
          />
          <CustomDropzone
            onDrop={(files) => form.setFieldValue('file', files)}
            files={form.values.file}
            resetFile={() => form.setFieldValue('file', [])}
            mimeType="docxPdf"
          />
          <Group justify="end">
            <ButtonCancel onClose={onClose} />
            <ButtonSave isLoading={isLoading} />
          </Group>
        </Stack>
      </form>
      <SupportedKeysLegend />
    </>
  );
}
