import { Button, Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconTableDown, IconTableImport, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import DailyWorkService from '../../../services/DailyWorkService';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission, isAccountant } from '../../../utils/authorization';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import saveDownloadedfile from '../../../utils/saveDownloadedFile';
import ImportActivitiesForm from '../../activities/my-activities/components/ImportActivitiesForm';

type Props = {
  companyId: string;
  divisionIds: string[];
  month: number;
  year: number;
  refresh: () => void;
};

export default function ImportExportActivitiesButtons({
  companyId,
  divisionIds,
  month,
  year,
  refresh,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();

  const { mutate: exportActivitiesDivisions, isLoading: isLoadingExport } =
    useMutation({
      mutationFn: () =>
        DailyWorkService.exportUserDailyWorkByDivisions(
          divisionIds,
          month,
          year
        ),
      onSuccess: (data) => {
        saveDownloadedfile(data);
      },
      onError: (error) =>
        showNotification({
          id: 'export-dailywork-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          icon: <IconX />,
          color: 'red',
        }),
    });

  function openImport() {
    modals.open({
      modalId: `import-user-activities`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.import')}
        </Title>
      ),
      size: 'xl',
      children: (
        <ImportActivitiesForm
          exportClick={exportActivitiesDivisions}
          refresh={refresh}
        />
      ),
    });
  }

  return (
    <Group>
      <Button
        key={'import-button'}
        disabled={
          !hasPermission(
            {
              permission: BuiltInPermissions.AuthorizedUpsertDailyWork,
              scope: ScopeEnum.ALL,
            },
            user
          ) && !isAccountant(user, companyId)
        }
        leftSection={<IconTableImport />}
        variant="outline"
        onClick={() => openImport()}
      >
        {`${t('w.import')} ${t('w.activities').toLowerCase()}`}
      </Button>
      <Button
        key={'export-button'}
        disabled={isLoadingExport}
        leftSection={<IconTableDown />}
        variant="outline"
        loading={isLoadingExport}
        onClick={() => exportActivitiesDivisions()}
      >
        {`${t('w.export')} ${t('w.activities').toLowerCase()}`}
      </Button>
    </Group>
  );
}
