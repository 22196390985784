import { Flex, Text, UnstyledButton } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import goToUserProfile from '../../utils/goToUserProfile';
import CustomUserAvatar from '../CustomAvatar/CustomUserAvatar';
import s from './GoToUserButton.module.sass';

type Props = {
  userId: string;
  userFullname: string;
  disabled?: boolean;
};

export default function GoToUserButton({
  userId,
  userFullname,
  disabled = false,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <UnstyledButton
      onClick={() => {
        if (!disabled) {
          goToUserProfile(user, navigate, t, userId, userFullname);
        }
      }}
      className={s.root}
    >
      <Flex align="center" gap={4}>
        <CustomUserAvatar userId={userId} size={26} />
        <Text size="sm" className={s.root}>
          {userFullname}
        </Text>
      </Flex>
    </UnstyledButton>
  );
}
