import { Button, Stack, TextInput } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { isNotEmpty, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';

import ButtonSave from '../../../../../components/ButtonSave';
import CustomDropzone from '../../../../../components/CustomDropzone/CustomDropzone';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import DocumentGeneratorService from '../../../../../services/DocumentGeneratorService';
import { Template } from '../../../../../types/api/response/documentGenerator';
import { downloadDocumentGeneratorTemplate } from '../../../../../utils/attachmentFile';
import SupportedKeysLegend from './SupportedKeysLegend';

type Props = {
  companyId: string;
  template: Template;
  refetch: () => void;
  onClose: () => void;
};

type FormValues = {
  templateName: string;
  file: FileWithPath[];
};

export default function ContractTemplateEditForm({
  companyId,
  template,
  refetch,
  onClose,
}: Props) {
  const { access_token } = useAuth();
  const { t } = useI18n();
  const currentTemplateFileName = `${template.name}.${
    template.file?.split('.').pop() ?? 'docx'
  }`;
  const form = useForm<FormValues>({
    initialValues: { file: [], templateName: template.name },
    validate: {
      templateName: isNotEmpty(t('w.required')),
    },
  });

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: (payload: FormValues) =>
      DocumentGeneratorService.updateTemplate({
        companyId,
        templateId: template.id,
        templateName: payload.templateName,
        file: payload.file[0],
      }),
    onSuccess: () => {
      showNotification({
        id: 'DocumentGeneratorService.updateTemplate-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      onClose();
    },
    onError: () => {
      showNotification({
        id: 'DocumentGeneratorService.updateTemplate-error',
        message: t('w.error'),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  return (
    <>
      <form onSubmit={form.onSubmit((values) => mutateUpdate(values))}>
        <Stack>
          <TextInput
            required
            label={<LabelText text={t('w.name')} />}
            {...form.getInputProps('templateName')}
          />
          <Stack>
            <CustomDropzone
              onDrop={(files) => form.setFieldValue('file', files)}
              files={form.values.file}
              resetFile={() => form.setFieldValue('file', [])}
              mimeType="docxPdf"
            />
            <Button
              variant="light"
              onClick={() =>
                downloadDocumentGeneratorTemplate(
                  template.id,
                  currentTemplateFileName,
                  companyId,
                  access_token
                )
              }
            >
              {t('w.downloadCurrentVersion')}
            </Button>
          </Stack>
          <ButtonSave isLoading={isLoadingUpdate} />
        </Stack>
      </form>
      <SupportedKeysLegend />
    </>
  );
}
