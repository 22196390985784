import { Button, Flex, Group, Image, Text } from '@mantine/core';
import dayjs from 'dayjs';
import * as React from 'react';

import InformationMessage from '../../../components/InformationMessage';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { isHiFiveWorkDemo } from '../../../utils/projects';
import {
  HISTOPAIE_HOST,
  MS_WHITE_LABEL_ID,
} from '../../../variables/GlobalVariables';

type Props = {
  userId: string;
};

export default function HistoPaieConnector({ userId }: Props) {
  const { user } = useAuth();
  const { t } = useI18n();

  function onConnectClick() {
    window.open(
      `${HISTOPAIE_HOST}/register?origin=hifivework&empId=${userId}`,
      '_blank'
    );
  }

  function goToHistoPaieLoginPage() {
    window.open(HISTOPAIE_HOST, '_blank');
  }

  function onDownloadCertificateClick() {
    window.open(
      `https://certificates.infocert.org/ouvrir_certificat.php?id_elmt=3613&fichier=CERTIF_203483-1_EXASECURITY_EXASAFE+%283%29.pdf&HR=09:21:04${dayjs()
        .utc()
        .format('HH:MM:SS')}`,
      '_blank'
    );
  }

  // TODO enlever le check sur demo pour la prod
  if (
    MS_WHITE_LABEL_ID === 'AFRICAPAIERH' ||
    user.id !== userId ||
    !isHiFiveWorkDemo()
  )
    return null;

  return (
    <InformationMessage
      title={t('histopaie.safeTitle')}
      message={
        <Flex gap="md" align="center">
          <Image
            radius="md"
            h={150}
            w="auto"
            fit="contain"
            src="/images/certif/afnor.png"
          />
          <Group>
            <Text>{t('histopaie.safeDesc1')}</Text>
            <Text>{t('histopaie.safeDesc2')}</Text>
            <Button variant="outline" onClick={onDownloadCertificateClick}>
              {t('histopaie.downloadCertificate')}
            </Button>
            {user.histoPaie ? (
              <Button onClick={goToHistoPaieLoginPage} mb="md">
                {t('histopaie.showPayslips')}
              </Button>
            ) : (
              <Button onClick={onConnectClick}>
                {t('histopaie.createAccount')}
              </Button>
            )}
          </Group>
        </Flex>
      }
    />
  );
}
