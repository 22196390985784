import type { FileWithPath } from '@mantine/dropzone';

import {
  DocGenActionType,
  DocGenExportType,
  DocgenUpdateRequest,
} from '../types/api/payload/documentGenerator';
import {
  Template,
  TemplateMapping,
} from '../types/api/response/documentGenerator';
import { normalizeFile } from '../utils/normalizeFile';
import { MS_HIFIVEWORK_PROFILE_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function createTemplate(
  companyId: string,
  templateName: string,
  file: FileWithPath
): Promise<Template> {
  const formData = new FormData();
  formData.append('file', normalizeFile(file));
  formData.append('fileName', file.name);
  formData.append('templateName', templateName);

  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/document-generator/company/${companyId}/template`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

function getTemplate(companyId: string, templateId: string): Promise<Template> {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/document-generator/company/${companyId}/template/${templateId}`
    )
    .then((res) => res?.data);
}

function getTemplates(companyId: string): Promise<Template[]> {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/document-generator/company/${companyId}/template`
    )
    .then((res) => res?.data);
}

function deleteTemplate(companyId: string, templateId: string): Promise<void> {
  return request
    .delete(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/document-generator/company/${companyId}/template/${templateId}`
    )
    .then((res) => res?.data);
}

function updateTemplate(payload: DocgenUpdateRequest): Promise<Template> {
  const formData = new FormData();
  formData.append('templateName', payload.templateName);
  payload.file && formData.append('file', normalizeFile(payload.file));
  payload.file && formData.append('fileName', payload.file.name);

  return request
    .patch(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/document-generator/company/${payload.companyId}/template/${payload.templateId}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    .then((res) => res?.data);
}

function getTemplateFile(companyId: string, templateId: string): string {
  return `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/document-generator/company/${companyId}/template/${templateId}/file`;
}

function generateDocuments(
  companyId: string,
  templateId: string,
  userIds: string[],
  actionType: DocGenActionType,
  exportType: DocGenExportType
): Promise<any> {
  return request
    .post(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/document-generator/company/${companyId}/template/${templateId}/generate/type/${exportType}/action/${actionType}`,
      { userIds },
      {
        responseType: 'blob',
      }
    )
    .then((res) => res);
}

function generateDocument(
  companyId: string,
  templateId: string,
  userId: string,
  actionType: DocGenActionType,
  exportType: DocGenExportType
): Promise<any> {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/document-generator/company/${companyId}/template/${templateId}/generate/${userId}/type/${exportType}/action/${actionType}`,
      {
        responseType: 'blob',
      }
    )
    .then((res) => res);
}

function getTemplateMappings(
  companyId: string,
  templateId: string
): Promise<TemplateMapping[]> {
  return request
    .get(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/document-generator/company/${companyId}/template/${templateId}/mapping`
    )
    .then((res) => res?.data);
}

function updateMapping(
  companyId: string,
  templateId: string,
  mappingId: string,
  entityKey?: string
): Promise<Template> {
  return request
    .patch(
      `${MS_HIFIVEWORK_PROFILE_ENDPOINT}/v2/document-generator/company/${companyId}/template/${templateId}/mapping/${mappingId}`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        params: {
          entityKey,
        },
      }
    )
    .then((res) => res?.data);
}

const DocumentGeneratorService = {
  createTemplate,
  getTemplate,
  getTemplates,
  deleteTemplate,
  updateTemplate,
  getTemplateFile,
  generateDocuments,
  generateDocument,
  getTemplateMappings,
  updateMapping,
};

export default DocumentGeneratorService;
