import { Button, Chip, Group, MultiSelect, Popover } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconMenuDeep, IconSelector } from '@tabler/icons-react';
import React from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import s from './ScreenWrapper.module.sass';

type Props = {
  value: string[];
  divisions: string[];
  onChange: (value: string[]) => void;
};

export default function AppBarChipGroupDivisions({
  value,
  divisions,
  onChange,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { company } = useCompany(user);
  const laptopMax = useMediaQuery('(max-width: 1439px)');

  return (
    <Group>
      <IconMenuDeep />
      {laptopMax || divisions.length > 3 ? (
        <Popover position="bottom" withArrow shadow="md">
          <Popover.Target>
            <Button leftSection={<IconSelector />} size={'sm'}>
              {company?.divisionLabel ? company.divisionLabel : t('w.unit')}
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            <MultiSelect
              value={value}
              data={divisions}
              onChange={onChange}
              classNames={{ input: s.input }}
              clearable
            />
          </Popover.Dropdown>
        </Popover>
      ) : (
        <Chip.Group multiple value={value} onChange={onChange}>
          <Group>
            {divisions.map((division) => (
              <Chip key={division} value={division}>
                {division === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division}
              </Chip>
            ))}
          </Group>
        </Chip.Group>
      )}
    </Group>
  );
}
